<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-account menu="quote"></submenu-account>
      
    <div class="submenu-content">
      <h2 class="pb-4">Quote Request</h2>
      <div class="desc">
        <p>Quote requested in the past 90 days.</p>
      </div>

      <spinner v-if="loading" />
      
      <!-- <pagination2
        v-if="!loading"
        :currentPage="currentPage"
        :pageCount="pageCount"
      /> -->
      
      <div class="tb">
        <table class="table table-condensed">
          <thead>
            <tr>
              <th scope="col">Quoted Date</th>
              <th scope="col">Quote ID</th>
              <th scope="col">Title</th>
              <!-- <th scope="col">Email</th> -->
              <th scope="col">Contact</th>
           
              <!-- <th scope="col">Ship To</th> -->
              <th scope="col">Web P.O.</th>
              <th scope="col">Quoted Price</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items">
              <td>{{item.created_at}}</td>
              <td>{{item.id}}</td>
              <td><a :href="'/account/quote-request/details/'+item.id">{{item.title}}</a></td>
              <!-- <td>{{item.email}}</td> -->
              <td>{{item.contact}}</td>
              
              <!-- <td><span v-if="item.saddr1">{{item.saddr1}},&nbsp;</span> 
                <span v-if="item.saddr2">{{item.saddr2}},&nbsp;</span>
                <span v-if="item.scity">{{item.scity}},&nbsp;</span> 
                <span v-if="item.sstate">{{item.sstate}} &nbsp;</span> 
                <span v-if="item.szipcode">{{item.szipcode}},&nbsp; </span> 
                <span v-if="item.scountry">{{item.scountry}} </span> 
              </td> -->
              <td><a v-if="item.status=='Converted'" :href="'/account/web-order/details/'+item.webso">{{item.webso}}</a></td>
              <td><span v-if="(item.status == 'Approved' || item.status == 'Converted') && item.total_quoted_price != null">{{formatPrice(item.total_quoted_price)}}</span></td>
              <td>{{item.status}}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div> 
   
  </div>
</template>

<script>
import submenuAccount from '../../components/nav/submenuAccount.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import Spinner from "../../components/Spinner.vue"
import Pagination2 from "../../components/Pagination2.vue"
import { ref } from 'vue'
import { getAuthAPI } from "../../utils/axios-api"
import { formatPrice } from '../../utils/common'

export default {
  name: "QuoteRequest",
  components: { submenuAccount, Pagination2, Spinner, BreadcrumbPage },
  setup() {
    const loading = ref(true)
    const error = ref("")
    // const pageCount = ref(null)
    // const currentPage = ref(1)
    const items = ref(null)

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "My Account", link: ""})
    breadcrumb_links.value.push({text: "Quote Request", link: "QuoteRequest"})

    //get quote list
    getAuthAPI.get('/order/quote-request/')
    .then(response => {
        console.error("---result---", response.data)
        items.value = response.data
        loading.value = false
    })
    .catch(err => {
      try {
        error.value = err.message
      }
      catch {
        error.value = "Something is wrong."
      }
      loading.value = false
    })
    
    return {
        loading,
        error,
        // pageCount,
        // currentPage,
        breadcrumb_links,
        items,
        formatPrice }
  }
}
</script>

<style>

</style>